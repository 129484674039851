
import { ref, onMounted } from 'vue'
import { checkBusinessLine } from '@/domains/stats/api'
import BaseCharts from '@/views/stats/base-charts.vue'

const searchBuline = (key: string, ctx: any) => {
  ctx.loading.value = true
  checkBusinessLine({ path: '/' }).then((res: any) => {
    if (res.items && res.items.length) {
      ctx.options.value = res.items.map((v) => {
        return {
          path: v.path,
          name: v.name
        }
      })
      ctx.loading.value = false
    }
  })
}
const categoryOps: { id: number; name: string }[] = [
  { id: 2, name: '活跃' },
  { id: 1, name: '新增' },
  { id: 3, name: '老用户' },
  { id: 4, name: '回流用户' }
]

export default {
  name: 'StatBu',
  components: { BaseCharts },
  setup() {
    const refCurrentBu = ref<string>('bu5')
    const refBuList = ref<any[]>([])

    onMounted(() => {
      checkBusinessLine({ path: '/' }).then((res: any) => {
        if (res.items && res.items.length) {
          refBuList.value = res.items.map((v) => {
            return {
              path: v.path,
              name: v.name,
              tab: `bu${v.path.replace(/\//g, '')}`
            }
          })
        }
      })
    })
    return {
      refCurrentBu,
      refBuList,
      searchBuline,
      categoryOps
    }
  }
}
