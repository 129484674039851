<template>
  <div class="page-stats-dashboard">
    <div class="bu-section">
      <el-tabs v-model="refCurrentBu">
        <el-tab-pane lazy v-for="bu in refBuList" :label="bu.name" :key="bu.path" :name="bu.tab">
          <BaseCharts :category-ops="categoryOps" targetType="business" :path="bu.path" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script lang="ts">
import { ref, onMounted } from 'vue'
import { checkBusinessLine } from '@/domains/stats/api'
import BaseCharts from '@/views/stats/base-charts.vue'

const searchBuline = (key: string, ctx: any) => {
  ctx.loading.value = true
  checkBusinessLine({ path: '/' }).then((res: any) => {
    if (res.items && res.items.length) {
      ctx.options.value = res.items.map((v) => {
        return {
          path: v.path,
          name: v.name
        }
      })
      ctx.loading.value = false
    }
  })
}
const categoryOps: { id: number; name: string }[] = [
  { id: 2, name: '活跃' },
  { id: 1, name: '新增' },
  { id: 3, name: '老用户' },
  { id: 4, name: '回流用户' }
]

export default {
  name: 'StatBu',
  components: { BaseCharts },
  setup() {
    const refCurrentBu = ref<string>('bu5')
    const refBuList = ref<any[]>([])

    onMounted(() => {
      checkBusinessLine({ path: '/' }).then((res: any) => {
        if (res.items && res.items.length) {
          refBuList.value = res.items.map((v) => {
            return {
              path: v.path,
              name: v.name,
              tab: `bu${v.path.replace(/\//g, '')}`
            }
          })
        }
      })
    })
    return {
      refCurrentBu,
      refBuList,
      searchBuline,
      categoryOps
    }
  }
}
</script>
<style lang="scss">
.page-stats-dashboard {
  background: #f4f4f4;
  margin: 0 -1rem;
  min-height: 100%;
  overflow: hidden;
  padding: 0 1rem;
  position: relative;
  .bu-section {
    background: #fff;
    border-radius: 1rem;
    margin: 1rem 0;
    padding: 1rem 0;
  }
  h1 {
    font-size: 20px;
    font-weight: 500;
    padding-left: 12px;
  }
}
</style>
